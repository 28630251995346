/* Main Container Styles */
.social-footer {
    /* background-color: #1a202c; Tailwind's bg-gray-900 equivalent */
    color: #ffffff; /* Tailwind's text-white equivalent */
    padding: 4rem 0; /* Tailwind's py-10 equivalent */
  }
  
  /* Heading Styles */
  .social-footer h2 {
    text-align: center;
    font-size: 3.5rem; /* Tailwind's text-2xl equivalent */
    font-weight: bold;
    margin-bottom: 2rem; /* Tailwind's mb-8 equivalent */
  }
  
  /* Grid Container */
  .social-footer .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem; /* Tailwind's gap-8 equivalent */
  }
  
  @media (max-width: 768px) {
    .social-footer .grid {
      grid-template-columns: 1fr; /* Single-column layout on smaller screens */
    }
  }
  
  /* Column Styles */
  .social-footer .column {
    text-align: center;
  }
  
  /* Column Heading Styles */
  .social-footer .column h3 {
    font-size: 2rem; /* Tailwind's text-xl equivalent */
    font-weight: 600; /* Tailwind's font-semibold equivalent */
    margin-bottom: 1rem; /* Tailwind's mb-4 equivalent */
  }
  
  /* Social Media Icon Styles */
  .social-footer .social-icons {
    display: flex;
    justify-content: center;
    gap: 2rem; /* Tailwind's space-x-4 equivalent */
  }
  
  .social-footer .social-icons a {
    width: 2rem; /* Tailwind's w-8 equivalent */
    height: 2rem; /* Tailwind's h-8 equivalent */
    display: inline-block;
    border-radius: 50%; /* Circular icons */
  }
  
 