/* Podcast Hero css */

.podcast-hero {
    position: relative;
    min-height: 90vh;
}

@media (max-width: 640px) {
    .podcast-hero {
        height: 50vh;
    }
}

@media (min-width: 768px) {
    .podcast-hero {
        height: 90vh;
    }
}

.podcast-hero img {
    width: 100%;
    height: 100%;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}

.podcast-hero .hero {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.podcast-hero .hero-content {
    width: 100%;
    padding: 2rem;
}

@media (min-width: 768px) {
    .podcast-hero .hero-content {
        padding: 1.5rem;
    }
}

@media (max-width: 640px) {
    .podcast-hero .hero-content {
        padding: 2rem;
    }
}

.podcast-hero h1 {
    font-size: 3rem;
    font-weight: bold;
    color: white;
    margin-bottom: 1.5rem;
}

@media (max-width: 640px) {
    .podcast-hero h1 {
        font-size: 2rem;
    }
}

@media (min-width: 768px) {
    .podcast-hero h1 {
        font-size: 3.5rem;
    }
}

@media (min-width: 1024px) {
    .podcast-hero h1 {
        font-size: 4.5rem;
    }
}



/* Podcast  */

#product {
    margin-top: 6rem;
    padding: 0px 4rem;
}

/* Adjustments for the video grid container */
.video-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
}

@media (min-width: 640px) {

    /* On small screens, use two columns */
    .video-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {

    /* On larger screens, use four columns */
    .video-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* Custom styles for the individual video cards */
.video-card {
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    width: 100%;
    height: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.video-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-card .overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-card .play-button {
    color: white;
    font-size: 2rem;
    background-color: red;
    padding: 1rem;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.video-card .play-button:hover {
    transform: scale(1.2);
}