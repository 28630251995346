.transparent-background:hover,
.transparent-background:focus {
  background-color: transparent;
}


.service-navbar-image {
  width: 25.47px;
  height: 25.47px;
  margin-right: 5px;
  border-radius: 6px;
}

.service-navbar-border {
  border-bottom: 1px solid #1a1a1a;
}

.service-navbar-title {
  color: white;
  font-weight: 500;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: start;

  // margin-bottom: 10px;
  // margin: 0 !important;
  @media (max-width: 768px) {
    font-size: 14px;
  }

  &:hover {
    color: #800000;
    transition: color 0.3s ease;
  }
}

// services
.services-main-div {
  @media only screen and (max-width: 600px) {
    padding: 10px;
  }
}

// service one
.top2 {
  margin-bottom: 4rem;
  align-items: center;
  text-align: center;

  @media (min-width: 768px) {
    margin-bottom: 0;
    width: 50%;
    text-align: left;
  }

  @media (min-width: 1024px) {
    padding-right: 6rem;
    flex-grow: 1;
  }
}

.service-heading-one {
  font-size: 36px;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
}

.para-one {
  text-align: justify;
  margin-right: 25px;

  @media only screen and (max-width: 600px) {
    text-align: center;
    padding-left: 7px;
    padding-right: 7px;
    margin-right: 0px;
  }
}

.scroll-h2 {
  font-size: 28px !important;
}

.scroll-h3 {
  font-size: 26px !important;
}

.scroll-para {
  font-size: 20px !important;
}

.para-twoo {
  text-align: justify;
  font-size: 20px !important;
  color: rgb(226, 217, 217);
}

// service second
.service-two {
  margin-bottom: 1.25rem;
  margin-top: 7rem;
  width: 100%;
}

.service-heading-two {
  font-size: 28px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 22px;
  }
}

.para-two {
  text-align: center;

  @media only screen and (max-width: 600px) {
    padding-left: 8px;
    padding-right: 8px;
  }
}

// serivce-extra-data
.extra-heading {
  margin-top: 40px;
  font-size: 28px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 22px;
  }
}

.extra-text {
  text-align: center;
}

.extra-text2 {
  display: flex;
  text-align: justify;
  padding-left: 10px;
  padding-right: 10px;

  i {
    margin-top: 4px;
    color: #800000;
    font-size: 18px;

  }
}

//service third
.service-three {
  padding-top: 2rem;
  padding-bottom: 2rem;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 8rem;
}

.service-three-head {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: #ffffff;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

}

.talk {
  margin-left: 41%;

  @media (max-width: 767px) {
    margin-left: 19%;
  }
}

// service-cards-fourth
// other css for slugs in last
.card-heading {
  margin-top: 40px;
  font-size: 28px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 22px;
  }
}

.card-text {
  text-align: justify;

  @media only screen and (max-width: 600px) {
    padding-left: 8px;
    padding-right: 8px;
  }
}

// for points extra(same as for extra data above)
.extra-points {
  margin-top: 40px;
}


// for images and heading (other slugs same)
.images-and-heading {
  margin-top: 20px;
}

// service-fifth-six and nftforextra

.heading-fifth {
  text-align: center;
  margin-top: 60px;

  font-size: 28px;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
}

.fifth-para {
  text-align: center;
  margin-bottom: 24px;
}

.fifth-para2 {
  // text-align: center;
  text-align: justify;
}

.heading-fifth2 {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 10px;

  @media only screen and (max-width: 600px) {
    padding-left: 10px;
    padding-right: 8px;

  }

}

.tick-check {
  color: #800000;
  font-size: 23px;
}

.arrow-head {
  color: #800000;
  font-size: 18px;
}

.icon-service {
  background-color: #800000;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  color: var(--color-white);
  text-align: center;
  min-width: 25px;
  font-size: 17px;


}

// hiring

.hiring-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: auto;
  border-radius: 10px;

}

.hiring-head {
  text-align: center;
  line-height: 1.5;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  font-size: 18px;
}

.hiring-text {
  line-height: 1.5;
  // text-align:  justify;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  text-align: center;
}

// services list navbar

.services-li {
  padding-bottom: 5px;

  @media only screen and (max-width: 768px) {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
  }
}

.nav-service-head {
  font-size: 18px;

}










// service

.upper-one {
  width: 390px;
  height: 50%;

  h3 {
    word-wrap: normal;
    // text-align: center;
    transition: all 400ms;
    padding-top: 13px;
    font-size: 26px;

    &:hover {
      transform: scale(1);
      color: #800000;
    }
  }

  img {
    padding-top: 15px;
    display: block;
    // margin: auto;
  }

  p {
    // text-align: center;
    // text-align: left;
    word-wrap: normal;
  }

}

@media (min-width: 320px) and (max-width: 425px) {


  .upper-one {
    width: 100%;
    height: auto;
    margin-left: 15px;
    padding: 10px;

    h3 {
      font-size: 17px;
    }

    p {
      font-size: 14px;
      margin-left: 20px;
      padding-bottom: 0px;
    }
  }
}

@media screen and (min-width: 425px) and (max-width: 580px) {
  .upper-one {
    width: 100%;
    margin-left: 15px;
    height: auto;
    padding: 10px;

    p {
      font-size: 14px;
      padding-bottom: 0px;
      margin-left: 20px;
    }
  }
}

@media screen and (min-width: 570px) {

  .upper-one {
    width: 100%;
    margin-left: 2px;
    padding: 10px;

    p {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 767px) {


  .upper-one {
    width: 100%;
    margin-left: 14px;
    padding-bottom: 10px;
    height: fit-content;

    p {
      font-size: 14px;
      // margin-left: 20px;
      // padding-bottom: 10px;
    }
  }
}

@media screen and (min-width: 1023px) {


  .upper-one {
    width: 100%;
    height: fit-content;
    margin-left: 0px;


    p {
      // padding-left: 0px;
      padding: 5px;
      font-size: 22px;
      color: white;
    }


  }
}


.carousel-container {
  // position: relative;
  width: 100%;
  overflow: hidden;
  padding: 3% 5%;
}

.carousel-container-background-color1 {
  // background-color: #330707;
  background: rgb(0, 0, 0);
  background: linear-gradient(127deg, rgba(0, 0, 0, 1) 70%, rgba(51, 7, 7, 1) 100%);
  // background-color: #0f0f11;
  border: 1px solid rgb(97, 96, 96);
}

.carousel-container-background-color2 {
  background-color: #949494;
}

// .carousel-slider {
//   display: flex;
//   overflow: hidden;
//   width: 100%;
//   // padding-left: 5%;
// }

// .carousel-wrapper {
//   display: flex;
//   transition: transform 0.5s ease;
//   width: calc(100% * 4); 
// }

// .carousel-itemm {
//   flex: 1 0 33.333%; 
//   box-sizing: border-box;
//   padding: 10px;
//   margin: 0 10px;
//   background-color: white; 
// }

// .carousel-itemm img {
//   width: 100%;
//   height: auto;
//   display: block;
//   margin: auto;
// }

// .carousel-button {
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   background-color: rgba(0, 0, 0, 0.5);
//   color: white;
//   border: none;
//   padding: 10px;
//   cursor: pointer;
//   z-index: 10; 
// }

// .carousel-button.prev {
//   left: 10px;
// }

// .carousel-button.next {
//   right: 10px;
// }

// .carousel-itemm h3 {
//   font-size: 18px;
//   margin: 10px 0;
//   color: #000;
// }

.carousel-item p {
  font-size: 14px;
  color: white !important;
}

.right-column {
  overflow-y: auto;
  height: 60vh;
}



.scroll-section {
  height: auto;
  margin-top: 2%;
  margin-bottom: 2%;
}

.bg-navy {
  background-color: #1E1F43;
}

.btn-border {
  border: none;
}

.Faq-Img {
  max-width: 140%;
  padding-top: 30%;
  padding-bottom: 30%;
  margin-left: -5%;
}

.in-Contrast-to-Black-Color {
  // background-color: #330707;
  background: rgb(0, 0, 0);
  background: linear-gradient(127deg, rgba(0, 0, 0, 1) 70%, rgba(51, 7, 7, 1) 100%);
  color: white !important;
  border: 1px solid rgb(97, 96, 96);
}

.btn-round {
  border-radius: 10%;
}

.accordian-color {
  background-color: #949494;
}

.text-align-justify {
  text-align: justify;
}

.section-height {
  min-height: 600px;
  display: flex;
  align-items: center;
}