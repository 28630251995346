/*-----------------------
    Footer Style Here  
------------------------*/
.logo {
  a {
    img {
      max-height: 45px;

    }
  }

  @media (max-width: 767px) {

    display: flex;
    justify-content: center;
    align-items: center;

  }
}

.footer-style-default {
  border-top: 1px solid var(--color-border);
  background-color: var(--color-darkest);
  background-image: -webkit-gradient(linear,
      left bottom,
      left top,
      from(var(--color-black)),
      to(var(--color-blacker)));
  background-image: linear-gradient(0deg,
      var(--color-black),
      var(--color-blacker));

  .footer-top {
    padding: 05px 0;

    // padding-bottom: 20px;
    .rn-footer-widget {
      margin-top: 3rem;

      @media #{$sm-layout} {
        padding-right: 1.3rem;
        padding-left: 1.3rem;
      }

      .text-big {
        font-size: 21px;
        margin-top: 30px;
        padding-right: 13%;
        line-height: 1.7;
        font-weight: 500;
        color: var(--color-body);
        margin-bottom: 30px;

        @media #{$md-layout} {
          font-size: 20px;
          padding-right: 0;
        }

        @media #{$sm-layout} {
          font-size: 20px;
          padding-right: 0;
        }
      }

      .title {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
        margin-bottom: 10px;
        color: var(--color-heading);

        @media #{$sm-layout} {
          text-align: center;
        }
      }

      .subtitle {
        font-weight: 400;
        color: var(--color-body);
        font-size: 16px;
        padding-right: 31%;
        line-height: 28px;
        text-align: justify;
        margin-bottom: 32px;

        @media #{$sm-layout} {
          padding-right: 0;
        }
      }

      .inner {
        margin-top: 13px;
      }

      .footer-link {
        list-style: none;
        padding: 0;

        li {
          margin-top: 5px;
          margin-bottom: 5px;

          span {
            font-size: 14px;
            display: inline-block;
            // color: var(--color-body);
            line-height: 25px;
            transition: 0.3s;
            // &:hover {
            //     color: var(--color-primary);
            //     &::after {
            //         width: 100%;
            //         left: 0;
            //         right: auto;
            //     }
            // }
          }
        }
      }
    }
  }

  .logo {
    a {
      img {
        max-height: 45px;
      }
    }
  }

  &.variation-two {
    border-top: 0;
  }

  &.no-border {
    border-top: 0;
  }

  @media #{$sm-layout} {

    .footer-link,
    .titl2 {
      display: none;
    }
  }


}

.footer-style-2 {
  .copyright-style-one {
    background: transparent;
  }
}

// Newsletter Styles
.newsletter-form {
  display: flex;
  flex-wrap: wrap;

  input {
    flex-grow: 1;
    width: auto;
    margin-right: 10px;
    height: 50px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
}

.footer-style-default {
  .newsletter-form {
    .form-group {
      input {
        border-color: var(--color-border);
      }
    }
  }
}

.social-icons li {
  margin-left: 2rem;

}

.social-icons {
  justify-content: start;
}

@media #{$sm-layout} {

  .social-icons {
justify-content: center;
}
.timing{
  text-align: center;
}
}

.social-icons li:first-child,
.social-icons li:nth-child(4) {
  margin-left: 0rem;
}