/*----------------------
    Animation Css  
-----------------------*/
.post-scale {
    overflow: hidden;
    @extend %radius;
    img {
        transition: 0.5s;
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}


@keyframes customOne {
    from {
        transform: scale(1);
    }

    50% {
        transform: scale(0.90);
    }

    to {
        transform: scale(1);
    }
}

@keyframes customTwo {
    0% {
        transform: (translate(0.0px, 0.0px));
    }

    50% {
        transform: (translate(100.0px, 0.0px));
    }

    100% {
        transform: (translate(50.0px, 50.0px));
    }
}

.customOne {
    animation: customOne 2s infinite;
}




/* ------------------------
    Custom Animation 01 
----------------------------*/

@-webkit-keyframes headerSlideDown {
    0% {
        margin-top: -100px
    }

    to {
        margin-top: 0
    }
}

@keyframes headerSlideDown {
    0% {
        margin-top: -100px
    }

    to {
        margin-top: 0
    }
}


/*------------------------
	slidefadeinup
--------------------------*/

@-webkit-keyframes slideFadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 20%, 0);
        transform: translate3d(0, 20%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes slideFadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 20%, 0);
        transform: translate3d(0, 20%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.slideFadeInUp {
    -webkit-animation-name: slideFadeInUp;
    animation-name: slideFadeInUp;
}

/* -----------------------------------
    Custom Animation For All Page
---------------------------------------*/

@-webkit-keyframes moveVertical {
    to {
        opacity: 1;
        -webkit-transform: translateY(0)
    }
}

@keyframes moveVertical {
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}






/*--------------------------------
Scroll Down Button Animation  
----------------------------------*/
@keyframes scrollDown {
    0% {
        opacity: 0;
    }

    10% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(10px);
        opacity: 0;
    }
}


.moveImage {
    margin-left: -58px;
    animation-name : mymove;
    animation-iteration-count : infinite;
    animation-direction : alternate;
    animation-duration : 2s;
    @media only screen and (max-width: 768px) {
        
          margin-left: 0px; 
        
      }
      @media only screen and (min-width:768px) and (max-width: 888px) {
        
        margin-left: 113px; 
      
    }
  }
  
  @keyframes mymove {
    from {transform: translateY(-10px);}
    to {transform: translateY(30px);}
  }
