// Advance tab Styles

.advance-tab-button-1 {
  .tab-button-list {
    list-style: none;
    padding: 0;
    margin: -10px 0;
    li {
      margin: 10px 0;
    }

    .tab-button {
      cursor: pointer;
      padding: 17px 18px;
      border-radius: 10px;
      position: relative;
      z-index: 2;
      @media (max-width: 768px) {
        padding: 7px 18px;
    }
      &::before {
        position: absolute;
        content: '';
        background: var(--color-blackest);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: 0.3s;
        opacity: 0;
        transform: scale(0.9);
        z-index: -1;
        border-radius: 10px;
      }

      &:hover {
        &::before {
          opacity: 1;
          transform: scale(1);
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 100%;
        border-left: 20px solid var(--color-blackest);
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        margin-top: -20px;
        opacity: 0;
        transition: 0.3s;
        transform: scale(0.9);
        @media #{$md-layout} {
          display: none;
        }
        @media #{$sm-layout} {
          display: none;
        }
      }

      .title {
        color: var(--color-body);
        font-size: 20px;
        margin-bottom: 10px;
      }

      .description {
        margin-bottom: 0;
    text-align: justify;

      }
    }

    .react-tabs__tab--selected {
      .tab-button {
        .title {
          color: var(--color-white);
        }
        &::before {
          opacity: 1;
          transform: scale(1);
        }

        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

.advance-tab-content-1 {
  .thumbnail {
    img {
      border-radius: 10px;
    }
  }
}

// Right Align

.advance-tab-button-1.right-align .tab-button-list .tab-button::after {
  transform: scale(0.9) rotate(-180deg);
  left: auto;
  right: 100%;
}
.advance-tab-button-1.right-align
  .tab-button-list
  .react-tabs__tab--selected
  .tab-button::after {
  transform: scale(1) rotate(-180deg);
}
.advance-tab-button-1.right-top .tab-button-list .tab-button::after {
  left: 50%;
  transform: scale(0.9) translateX(-50%) rotate(45deg);
  bottom: 94%;
  top: auto;
}
.advance-tab-button-1.right-top
  .tab-button-list
  .react-tabs__tab--selected
  .tab-button::after {
  opacity: 1;
  transform: scale(1) translateX(-50%) rotate(-90deg);
}
